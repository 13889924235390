import Swiper from "swiper";
import { EffectCoverflow, Pagination, Navigation } from 'swiper/modules';

import 'swiper/css';
import "swiper/css/effect-coverflow";

export default class TestimonialList {
    private swiper: Swiper | null = null; // Declare the swiper property

    constructor() {
        this.swiper = new Swiper(".testimonials-list-container", {
            pagination: {
                el: ".swiper-pagination",
                dynamicBullets: true,
            },

            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev"
            }, 

            effect: "coverflow",
            grabCursor: true,
            centeredSlides: true,
            slidesPerView: 'auto',
            initialSlide: 1,
            coverflowEffect: {
                rotate: 0,
                stretch: 0,
                depth: 60,
                modifier: 8,
                slideShadows: true
            },
            modules: [EffectCoverflow, Pagination, Navigation],
        });

            if(typeof this.swiper.el.addEventListener === 'function') {
                // Add a click event listener to the testimonials-list-container
                this.swiper.el.addEventListener('click', this.handleButtonClick.bind(this));
            }
    }

    handleButtonClick(event: Event) {
        const target = event.target as HTMLElement;

        if (target.classList.contains('testimonial-button')) {
            const targetName = target.getAttribute('data-target');

            const contentWrappers = document.querySelectorAll('.testimonial-content-single');
            contentWrappers.forEach(content => {
                const contentName = content.getAttribute('data-content');
                if (contentName === targetName) {
                    content.classList.add('active');
                } else {
                    content.classList.remove('active');
                }
            });
        }
    }
}
