import Swiper from "swiper";
import { EffectCoverflow, Pagination, Navigation } from 'swiper/modules';

import 'swiper/css';
import "swiper/css/effect-coverflow";

export default class TestimonialSwiper {
    constructor() {
        const swiper = new Swiper(".testimonials-swiper-container", {

            pagination: {
                el: ".swiper-pagination",
                dynamicBullets: true,
              },
            
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev"
            },  

            effect: "coverflow",
            grabCursor: true,
            centeredSlides: true,
            slidesPerView: 'auto',
            initialSlide: 2,
            loop: true,
            coverflowEffect: {
                rotate: 0,
                stretch: 0,
                depth: 60,
                modifier: 8,
                slideShadows: true
            },

            modules: [ EffectCoverflow, Pagination, Navigation ],
        });
    }
}


