import '@styles/main.scss';
import TestimonialSwiper from "@scripts/components/testimonial-swiper";
import TestimonialList from './components/testimonial-list';
import CookieAccept from './cookie-accept';

class App {
    constructor() {
        new TestimonialSwiper()
        this.mobileMenu();
        new TestimonialList();

        const text = 'This website uses cookies to ensure you get the best experience on our website. By accepting you agree to allow us to use cookies',
        cookieAccept = new CookieAccept(text);
 
         if (cookieAccept.shouldShow()) {
             cookieAccept.show();
         }

    }

    mobileMenu() {
        const trigger = document.getElementById('mobile-menu-trigger');
        const overlay = document.querySelector('.menu-overlay');

        const menu = document.querySelector('.nav');

        const close = () => {
            menu.classList.remove('is-active');
            trigger.classList.remove('is-active');
            document.documentElement.classList.remove('menu-open');
            overlay.classList.remove('is-active');
        }

        trigger.addEventListener('click', () => {
            menu.classList.toggle('is-active');
            trigger.classList.toggle('is-active');
            document.documentElement.classList.toggle('has-menu-open');
            overlay.classList.toggle('is-active');
        });

        overlay.addEventListener('click', () => {
            close();
        });
    }
}

document.addEventListener('DOMContentLoaded', () => {
    new App();
});
