export function h(tag, classes, textContent = '') {
    const el = document.createElement(tag);
    el.className = classes;
	el.textContent = textContent;
    return el;
}

export function setCookie(name, value, expDays) {
    const d = new Date(); //Create an date object
    d.setTime(d.getTime() + (expDays * 1000 * 60 * 60 * 24)); //Set the time to exdays from the current date in milliseconds. 1000 milliseonds = 1 second
    const expires = 'expires=' + d.toGMTString(); //Compose the expirartion date
    window.document.cookie = name + '=' + value + '; ' + expires + '; path=/'; //Set the cookie with value and the expiration date
}
